<template>
  <v-app-bar ref="topbar" class="topbar">
    <v-app-bar-nav-icon v-if="drawer" @click="toggleSidebar"></v-app-bar-nav-icon>
    <slot name="beforeTitle"></slot>
    <v-spacer></v-spacer>
    <v-toolbar-title class="title">{{title}}</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn v-if="shipList" icon @click="openShipsImportDialog">
      <v-icon>fas fa-file-import</v-icon>
    </v-btn>
    <v-btn v-if="shoppingCard.length" class="mr-2" icon @click="openShoppingCard">
      <v-badge :content="quantity">
        <v-icon>fa-shopping-cart</v-icon>
      </v-badge>
    </v-btn>
  </v-app-bar>
</template>

<script>
  import {eventBus} from '../main';

  export default {
    name: 'Topbar',

    props: {
      loading: Boolean,
      drawer: Boolean,
      title: {
        type: String,
        default: ''
      },
      shipList: Boolean,
      shoppingCard: {
        type: Array,
        default: () => {
          return [];
        }
      },
      openShoppingCard: {
        type: Function,
        default: () => {}
      },
      bulkList: {
        type: Array,
        default: () => {
          return [];
        }
      },
      openShipsImportDialog: {
        type: Function,
        default: () => {}
      },
    },

    data() {
      return {
        quantity: 0
      }
    },

    watch: {
      shoppingCard: {
        deep: true,
        handler(shoppingCard) {
          let quantity = 0;
        for (let i = 0; i < shoppingCard.length; i++) {
          quantity += shoppingCard[i].quantity;
        }
        this.quantity = quantity;
        }
      }
    },

    methods: {
      toggleSidebar() {
        eventBus.$emit('toggleSidebar');
      }
    }
  };
</script>

<style lang="scss" scoped>
  .title {
    position: absolute;
    left: 0;
    z-index: -50;
    width: 100%;
    text-align: center;
  }
</style>
